/**
 * StandardPage
 */
import React, { useEffect, useState } from 'react';
import { StandardPageModel } from 'types/pages';
import Breadcrumb from 'components/Breadcrumb';
import EpiFragments from 'components/EpiFragments';
import BannerImage from 'components/BannerImage';
import { Grid, Cell } from 'components/Grid';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { smoothScrollToTarget } from 'utils/smoothScrollToTarget';
/** Standard page component. */
const StandardPage: React.FC<StandardPageModel> = ({
	heading,
	preamble,
	text,
	bannerImage,
	toc,
}) => {
	const [renderedFragments, setRenderedFragments] = useState<JSX.Element[][]>();
	const [tocItems, setTocItems] = useState<any[]>([]);
	// New state to track the ID of the currently active section
	const [activeSection, setActiveSection] = useState<string | null>(null);

	const isMobile = useMediaQuery({
		maxWidth: 768,
	});

	// Function to get all "raw" values for "HeadingFragment"
	function getFragments(data: any) {
		const mergingFragments: any = [];
		const fragment =
			data.modelType === 'HeadingFragment' ? (
				<h2
					id={data.id}
					key={data.id}
					className="font-standard font-medium text-h2 my-4"
					dangerouslySetInnerHTML={{ __html: data.raw }}
				></h2>
			) : (
				<EpiFragments fragments={[data]} />
			);
		mergingFragments.push(fragment);
		return mergingFragments;
	}

	function processFragments() {
		const mergedFragments = [];
		for (let i = 0; i < text.fragments.length; i++) {
			const fragmentsSorted = getFragments(text.fragments[i]);
			mergedFragments.push(fragmentsSorted);
		}
		setRenderedFragments(mergedFragments);
	}

	function getAllHeadingsFragment(): void {
		const fragments = text.fragments;
		const headingFragments = fragments.filter(
			(fragment: any) => fragment.modelType === 'HeadingFragment'
		);

		const heading = headingFragments.map((val: any) => val);
		setTocItems(heading);
	}

	useEffect(() => {
		processFragments();
		getAllHeadingsFragment();
	}, []);

	useEffect(() => {
		// Function to check which section is currently active based on scroll position
		function updateActiveSection() {
			const headings = tocItems.map((item) => item.id);
			let currentActiveSection: string = '';

			for (let i = headings.length - 1; i >= 0; i--) {
				const headingElement = document.getElementById(headings[i]);
				if (
					headingElement &&
					headingElement.getBoundingClientRect().top <= 200
				) {
					currentActiveSection = headings[i];
					break;
				}
			}

			if (currentActiveSection !== activeSection) {
				setActiveSection(currentActiveSection);
			}
		}

		// Add scroll event listener to update the active section on scroll
		window.addEventListener('scroll', updateActiveSection);

		// Clean up the event listener when the component is unmounted
		return () => {
			window.removeEventListener('scroll', updateActiveSection);
		};
	}, [tocItems, activeSection]);

	useEffect(() => {
		// Attach click event listeners to each anchor link
		const tocLink = document.querySelectorAll('.table-of-contents');
		tocLink.forEach((link) => {
			link.addEventListener('click', smoothScrollToTarget);
		});

		// Clean up the event listeners when the component is unmounted
		return () => {
			tocLink.forEach((link) => {
				link.removeEventListener('click', smoothScrollToTarget);
			});
		};
	}, []);

	return (
		<article aria-labelledby="sp-heading">
			{bannerImage && (
				<BannerImage heading={heading} imgSrc={bannerImage.src} />
			)}

			<div className="mx-auto main-w-container px-8 xl:px-0">
				<Breadcrumb className="mt-1 mb-2" />

				<Grid className="flex-nowrap justify-between mb-80">
					<Cell span={12} desktop={7}>
						{preamble && <p className="text-base font-bold mb-6">{preamble}</p>}
						{renderedFragments}
					</Cell>

					{!isMobile && tocItems.length > 0 && (
						<Cell span={12} desktop={4}>
							<nav
								aria-labelledby="toc-heading"
								className="table-of-contents bg-white rounded-xl border-2 border-grey-light p-6 w-[366px]"
							>
								<h2
									id="toc-heading"
									className="text-xl font-bold font-standard mb-4"
								>
									{toc}
								</h2>
								<div className="flex flex-col flex-nowrap items-baseline">
									{tocItems &&
										tocItems.map((item, i) => {
											// Check if the current option is selected
											const isSelected = item.id == activeSection;
											return (
												<a
													aria-label={item.raw} // TODO
													key={i.toString()}
													href={`#${item.id}`}
													className={clsx(
														'text-lg cursor-pointer h-auto',
														'px-6 py-1',
														isSelected
															? 'border-l-8 border-orange-400 selected'
															: 'border-l-8 border-grey'
													)}
													onClick={smoothScrollToTarget}
													dangerouslySetInnerHTML={{ __html: item.raw }}
												/>
											);
										})}
								</div>
							</nav>
						</Cell>
					)}
				</Grid>
			</div>
		</article>
	);
};

export default StandardPage;
