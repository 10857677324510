import clsx from 'clsx';
import Icon from 'components/Icon';
import Image from 'components/Image';
import MapContext from 'components/Map/MapContext';
import { MapBrowserEvent, Overlay } from 'ol';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import { Vector } from 'ol/source';
import { Circle, Style } from 'ol/style';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { MapArea } from 'types/mapTypes/MapModel';

interface Props {
	areaForecasts: MapArea[];
}

const ForecastLayer: React.FC<Props> = ({ areaForecasts = [] }) => {
	const { map } = useContext(MapContext);

	// This will contain the ref's we use to set the Markers
	// At mount, it will generate a list of empty references
	const forecastRefs = useRef<Array<HTMLAnchorElement | null>>([]);

	/**
	 * Will generate a list of (existing/new) refs which will either be empty or not depending on whether the UI was rendered.
	 * If the UI was rendered it should contain a list of empty references, else a list of populated element refs
	 * @see {@link https://stackoverflow.com/a/56063129/11677503 answer by Olivier Boissé}
	 * */
	const initForecastRefs = useCallback(() => {
		forecastRefs.current = forecastRefs?.current?.slice(
			0,
			areaForecasts.length
		);
	}, [areaForecasts]);

	useEffect(initForecastRefs, [areaForecasts]);
	useEffect(() => {
		function addHoverEffect(e: MapBrowserEvent<UIEvent>) {
			if (e.dragging) return;

			const pixel = map.getEventPixel(e.originalEvent);
			const hit = map.hasFeatureAtPixel(pixel);

			map.getTargetElement().style.cursor = hit ? 'pointer' : '';
		}

		if (!map) return;

		const layerSource = new Vector({});

		areaForecasts.forEach((area, areaIndex) => {
			const markerEl = forecastRefs.current[areaIndex];

			if (markerEl == null) return;

			var marker = new Overlay({
				id: 'marker' + areaIndex,
				position: area.coordinates,
				offset: [5, -17],
				element: markerEl,
				stopEvent: false,
			});

			map.addOverlay(marker);

			const circleFeature = new Feature({
				geometry: new Point(area.coordinates),
				name: area.name,
			});

			circleFeature.setStyle(
				new Style({
					image: new Circle({
						radius: 15,
					}),
				})
			);

			layerSource.addFeatures([circleFeature]);
		});

		const vectorLayer = new VectorLayer({
			source: layerSource,
			zIndex: 22,
		});

		// Adds Layer with Area Markers
		map.addLayer(vectorLayer);

		//To add hover effect on Area Markers
		map.on('pointermove', addHoverEffect);

		return () => {
			if (map) {
				map.removeLayer(vectorLayer);
				map.un('pointermove', addHoverEffect);
			}
		};
	}, [map, areaForecasts, forecastRefs.current]);

	return (
		<div
			className="bg-white group"
			style={{
				boxShadow: '2px 3px 6px 0px rgba(0, 0, 0, 0.2)',
			}}
		>
			{areaForecasts &&
				areaForecasts.map((area, areaIndex) => {
					return (
						<a
							key={areaIndex}
							// Use Callback Ref here to make sure that ForecastLayer Component is refreshed
							// This sets our list of empty refs (forecastRefs) with the elements DOM Node
							// Refer Line 22 for more.
							ref={(el) => (forecastRefs.current[areaIndex] = el)}
							id={`marker${areaIndex}`}
							className={clsx(
								'relative',
								'w-full',
								'rounded-md border border-grey-border',
								'group',
								'bg-white',
								'hover:underline',
								'flex'
							)}
							href={area.url}
						>
							<div
								className={clsx(
									'bg-white rounded-md',
									'h-8',
									'p-0 m-0',
									'transition ease-in-out duration-75',
									'bottom-0',
									'border-l border-solid'
								)}
							>
								<Image
									src={area.icon.src}
									alt={area.icon.alt ? area.icon.alt : ''}
									width={32}
									height={32}
								/>
							</div>
							<div
								className={clsx(
									'h-8 pr-2',
									'text-small font-semibold no-underline leading-8',
									'whitespace-nowrap'
								)}
							>
								{area.name}
								<span className="font-bold">
									<Icon size={99} icon="chevronSolid" direction="right" />
								</span>
							</div>
						</a>
					);
				})}
		</div>
	);
};

export default ForecastLayer;
