/**
 * StartPage
 */

import React, { useEffect, useState } from 'react';
import { StartPageModel } from 'types/pages';
import { useMediaQuery } from 'react-responsive';

import NewsBlock from 'components/NewsBlock';

import DesktopStartPage from 'components/DesktopStartPage';
import MobileStartPage from 'components/MobileStartPage';
import CardLinkList from 'components/CardLinkList';

/** Page template for startpage. */
const StartPage: React.FC<StartPageModel> = ({
	modelType,
	heading,
	puffs,
	puffsHeading,
	currentNews,
	announcement,
	areaPageLinksArea,
	map,
	mobileMapLabel,
	mobileAreasLabel,
	skipMap,
	_properties = {},
}) => {
	const isMobileOrTablet = useMediaQuery({
		maxWidth: 1023,
	});
	const [hasSmallHeight, setHasSmallHeight] = useState<boolean>();

	useEffect(() => {
		if (typeof window !== 'undefined') {
			setHasSmallHeight(window?.innerHeight < 900);
		}
	}, [hasSmallHeight]);

	return (
		<>
			<h1 className="sr-only">{heading}</h1>

			{isMobileOrTablet ? (
				<MobileStartPage
					areaPageLinksArea={areaPageLinksArea}
					map={map}
					mobileMapLabel={mobileMapLabel}
					mobileAreasLabel={mobileAreasLabel}
				/>
			) : (
				<DesktopStartPage
					areaPageLinksArea={areaPageLinksArea}
					map={map}
					skipMap={skipMap}
				/>
			)}
			{puffs && puffs.length > 0 && (
				<CardLinkList
					heading={puffsHeading}
					puffs={puffs}
					isMobileOrTablet={isMobileOrTablet ? 'small' : 'large'}
				/>
			)}

			{currentNews && (
				<div className="p-8 lg:px-0">
					<NewsBlock {...currentNews} />
				</div>
			)}
		</>
	);
};

export default StartPage;
