/**
 * ForecastListCard
 */

import clsx from 'clsx';
import Icon from 'components/Icon';
import Link from 'components/Link';
import React from 'react';

interface Props {
	/** Name of the Mountain Range */
	name: string;

	/** Weather Severity */
	severity: string;

	/** Date the forecast was issued on */
	issuedOn: string;

	/** Icon showing the weather severity and it's indicator (number) */
	image: {
		src: string;
		alt: string;
	};

	/** Additional information regarding forecast */
	description: string;

	/** URL which will re-route to when the card is clicked */
	url: string;

	/** Classnames */
	className?: string;

	/** Index */
	index: number;
}

/** Card displaying overview of a Forecast */
const ForecastListCard: React.FC<Props> = ({
	name,
	severity,
	issuedOn,
	image,
	description,
	url,
	className,
	index,
}) => {
	let dateStr = issuedOn.split(' ').slice(-2, -1)[0]; // Extract the date part

	let parsedDate,
		reversedDate = null; // Initialize parsedDate

	if (dateStr) {
		dateStr = dateStr.replace(/\//g, '-'); // for en lang
		const dateParts = dateStr.split('-');
		reversedDate = dateParts[2] + '-' + dateParts[1] + '-' + dateParts[0];
		parsedDate = new Date(reversedDate);
	}

	return (
		<Link
			aria-labelledby={`forecast-list-card-${index}`}
			to={url}
			className="forecast-list box-border flex flex-col items-center transition ease-in-out duration-300 group bg-white rounded-lg border border-gray-200 shadow-md hover:shadow-lg group h-img-card"
		>
			{image.src && (
				<div className="w-full bg-grey-light flex justify-center items-center">
					<img
						className="rounded-t-lg bg-grey-light block h-card-img object-cover w-32"
						src={image.src}
						alt={image.alt}
					/>
				</div>
			)}

			<div className="px-4 pt-4 pb-0 min-h-11 h-11 w-full">
				{name && (
					<h2
						id={`forecast-list-card-area-${index}`}
						className="text-xl leading-7 font-standard font-bold group-hover:underline flex items-baseline justify-between"
					>
						{name}
						<Icon size={1} icon="chevron" direction="left" className="mr-2" />
					</h2>
				)}
				{severity && (
					<h3
						id={`forecast-list-card-severity-${index}`}
						className="text-grey-darkest font-semibold select-none"
					>
						{severity}
					</h3>
				)}
				{reversedDate && parsedDate && (
					<p className="text-sm text-gray-800 font-normal select-none py-1">
						<time
							dateTime={parsedDate?.toISOString()}
							aria-label={reversedDate}
						>
							{issuedOn}
						</time>
					</p>
				)}

				<p className="leading-5 h-10 overflow-y-hidden">{description}</p>
			</div>
		</Link>
	);
};

export default ForecastListCard;
