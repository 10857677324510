/**
 * DatePickerInput
 */

import Icon from 'components/Icon';
import { enUS, sv } from 'date-fns/locale';
import dayjs from 'dayjs';
import React, { forwardRef, useEffect, useState } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useLocation } from 'react-router';

registerLocale('sv', sv);
registerLocale('en-US', enUS);

interface Props {
	/** Description of this property */
	publishedDate?: string;
	seePreviousForecastText?: string;
}

const CustomInput = forwardRef(
	(
		{
			onClick,
			seePreviousForecastText,
			areaExpanded,
			setAreaExpanded,
			isEnglish,
		}: any,
		ref: any
	) => {
		const handleClick = () => {
			if (onClick) {
				onClick();
			}
			setAreaExpanded((prev: boolean) => !prev);
		};
		return (
			<button
				aria-label={seePreviousForecastText}
				aria-describedby={
					isEnglish
						? 'Select a date from the calendar to update the displayed date'
						: 'Välj ett datum från kalendern för att uppdatera det visade datumet'
				}
				className="flex items-center"
				onClick={handleClick}
				ref={ref}
				aria-expanded={areaExpanded}
			>
				<Icon size={2} icon="calendar" />
				<span className="ml-3 underline">{seePreviousForecastText}</span>
			</button>
		);
	}
);

const DatePickerInput: React.FC<Props> = ({
	publishedDate,
	seePreviousForecastText,
}) => {
	const location = useLocation();

	const [currentDate, setCurrentDate] = useState<Date>(
		dayjs(publishedDate).toDate()
	);

	const [areaExpanded, setAreaExpanded] = useState<boolean>(false);

	function getDateQuery(date: Date, direction?: 'next' | 'before') {
		let day = dayjs(date);
		const dateFormatStr = 'YYYY-MM-DD';

		if (direction !== undefined) {
			day = direction === 'next' ? day.add(1, 'day') : day.subtract(1, 'day');
		}

		return `${location.pathname}?forecast_date=${dayjs(day).format(
			dateFormatStr
		)}`;
	}

	function renderDayContents(day: number, date: Date) {
		return (
			<a href={getDateQuery(date)} className="pl-1 pr-2">
				{day}
			</a>
		);
	}

	const handleCalendarClose = () => {
		setAreaExpanded(false);
	};

	const isEnglish = location.pathname.startsWith('/en/');

	const renderCustomPrevButtonLabel = () => {
		return isEnglish ? 'Previous Month' : 'Föregående månad';
	};

	const renderCustomNextButtonLabel = () => {
		return isEnglish ? 'Next Month' : 'Nästa månad';
	};

	const CustomHeader = ({ date, decreaseMonth, increaseMonth }: any) => (
		<div className="custom-header">
			<button
				type="button"
				className="react-datepicker__navigation react-datepicker__navigation--previous"
				aria-label={renderCustomPrevButtonLabel()}
				onClick={decreaseMonth}
			>
				&lt;
			</button>
			<span className="react-datepicker__current-month">
				{date.toLocaleString(isEnglish ? 'en-US' : 'sv', {
					month: 'long',
					year: 'numeric',
				})}
			</span>
			<button
				type="button"
				className="react-datepicker__navigation react-datepicker__navigation--next"
				aria-label={renderCustomNextButtonLabel()}
				onClick={increaseMonth}
			>
				&gt;
			</button>
		</div>
	);

	return (
		<ReactDatePicker
			aria-describedby="datepicker instructions"
			locale={isEnglish ? enUS : sv}
			onChange={(date) => setCurrentDate(date as Date)}
			closeOnScroll={(e) => e.target === document}
			selected={currentDate}
			allowSameDay={false}
			dateFormat="yyyy-MM-dd"
			className="text-center p-2 w-64 focus:outline-none cursor-pointer"
			customInput={
				<CustomInput
					seePreviousForecastText={seePreviousForecastText}
					isEnglish={isEnglish}
					areaExpanded={areaExpanded}
					setAreaExpanded={setAreaExpanded}
				/>
			}
			onCalendarClose={handleCalendarClose}
			renderDayContents={renderDayContents}
			popperPlacement="auto"
			popperClassName="custom-popper"
			renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
				<CustomHeader
					date={date}
					decreaseMonth={decreaseMonth}
					increaseMonth={increaseMonth}
				/>
			)}
		/>
	);
};

export default DatePickerInput;
