/**
 * PageHeader
 */

import React from 'react';
import LangButton from 'components/LangButton';
import Menu from 'components/Menu';
import HeaderLogo from 'components/PageHeader/HeaderLogo';
import Icon from 'components/Icon';
import { Link } from 'types/global';
import { ModelHeader } from 'types/epi';
import { useMediaQuery } from 'react-responsive';
import clsx from 'clsx';

interface Props {
	/** Pass an URL if the logo should be linked  */
	logoLink?: string;

	/** Logo image subtitle */
	logoSubtitle?: string;

	/** Language optionos */
	lang: Array<Link>;

	menu: any;
}

const menuBtnClass =
	'flex items-center justify-center px-2 mr-1 md:mr-0 min-w-menu-btn h-10 bg-grey-light hover:bg-gray-500 hover:shadow-md focus:outline-primary outline-reverse-offset-1 rounded-md uppercase bg-grey';

/** The page header component. */
const PageHeader: React.FC<ModelHeader> = (header) => {
	const { logo, lang, menu } = header;
	const [menuIsOpen, setMenuIsOpen] = React.useState(false);
	/** this bool allow to know if we are in mobile device or not */
	const isMobile = useMediaQuery({
		maxWidth: 768,
	});

	return (
		<>
			<header
				className="bg-white border-b-1 border-grey lg:shadow-md"
				tabIndex={-1}
			>
				<div className="main-w-container h-menu bg-white mx-auto responsive-padding header-page-padding flex items-center justify-between">
					{logo && (
						<HeaderLogo
							linkTo={logo.url}
							subtitle={logo.subtitle}
							ariaLabel={logo.ariaLabel}
						/>
					)}
					<div className="flex items-center gap-4 sm:gap-6 relative">
						{!isMobile &&
							lang?.items.map((item, index) => (
								<LangButton
									key={item.text + index.toString()}
									item={item}
									setMenuIsOpen={setMenuIsOpen}
									isMobile={isMobile}
								/>
							))}
						<button
							className={clsx(menuBtnClass, 'custom-border')}
							onClick={() => setMenuIsOpen(true)}
							aria-expanded={menuIsOpen}
							aria-label={
								menu?.closeText?.startsWith('Stäng')
									? 'Öppna meny'
									: 'Close menu'
							}
						>
							<Icon size={2} icon="menu" aria-hidden="true" />
						</button>
					</div>
				</div>
			</header>
			<Menu
				isOpen={menuIsOpen}
				setMenuIsOpen={setMenuIsOpen}
				menu={menu}
				lang={lang.items}
				langBtnStyle={menuBtnClass}
				logoLink={logo?.url}
				logoSubtitle={logo?.subtitle}
				logoAriaLabel={logo?.ariaLabel}
				isMobile={isMobile}
			/>
		</>
	);
};

export default PageHeader;
