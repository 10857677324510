import RichText from 'components/RichText';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { TableRowsBlock } from 'types/blocks';
import { Image } from 'types/global';

interface Props {
	/** Table Headings */
	tableHeadTexts: string[];

	/** Table Rows */
	tableRows: Array<TableRowsBlock>;

	/** Table Name */
	heading?: string;

	/** Table Caption */
	caption?: string;

	/** Table Foot notes */
	tableFootNotes?: string;

	/** Lavin logo */
	image: Image;

	className?: string;
}

const DesktopRiskTable: React.FC<Props> = ({
	heading,
	caption,
	image,
	tableHeadTexts,
	tableRows,
	tableFootNotes,
	className,
}) => {
	const location = useLocation();
	const isEnglish = location.pathname.startsWith('/en/');
	let scaleImage = isEnglish ? 'Risk Condition' : 'Riskförhållande';
	let riskLevel = isEnglish ? 'Risk Level' : 'Risknivå';
	let recommendation = isEnglish ? 'Risk Description' : 'Riskbeskrivning';
	return (
		<div className="table-risks-scales">
			<div className="flex justify-between">
				<h2 className="text-h3 lg:text-h2 font-bold mb-2" aria-label={heading}>
					{heading}
				</h2>
				<img
					src={image?.src}
					alt={image?.alt}
					aria-hidden="true"
					className="w-[100px] h-20"
				/>
			</div>
			<table tabIndex={0}>
				<caption className="sr-only">{caption}</caption>
				<thead className="bg-white">
					<tr>
						{tableHeadTexts.map((heading, index) => (
							<th
								key={index}
								scope="col"
								className="py-2 text-sm min-w-[120px]"
								aria-label={heading}
							>
								{heading}
							</th>
						))}
					</tr>
				</thead>
				<tbody className="border border-grey">
					{tableRows.map((row, index) => (
						<tr
							key={index}
							className="max-h-20 h-20"
							style={{
								borderTop: `4px solid ${row.cells[0]?.backgroundColor}`,
							}}
						>
							{row.cells.map((item, i) => {
								const {
									text,
									backgroundImage,
									backgroundColor,
									textColor,
									footNoteId,
								} = item;
								// Split the text if it contains " - " separator
								const parts = text?.includes(' - ')
									? text.split(' - ')
									: [text];
								const [numberPart, textPart] = parts; // Destructure the parts if applicable

								return (
									<>
										{backgroundImage ? (
											<td
												aria-label={scaleImage}
												key={i.toString() + '-' + backgroundImage?.alt}
												className="p-2 bg-white h-20"
											>
												<img
													src={backgroundImage?.src}
													alt={backgroundImage?.alt}
													aria-hidden="true"
													className="w-16 h-16 lg:w-20 lg:h-20"
												/>
											</td>
										) : (
											<>
												{text?.includes(' - ') ? (
													<td
														key={i.toString() + '-' + text}
														className="text-sm h-20"
														style={{
															background: `${backgroundColor}`,
														}}
														aria-label={riskLevel}
													>
														<p
															className="text-center"
															style={{
																color: `${textColor ? textColor : 'black'}`,
															}}
														>
															<span className="font-bold text-3xl">
																{numberPart}
															</span>
															<br />
															{textPart}
														</p>
													</td>
												) : (
													<td
														key={i.toString() + '-' + text}
														className="py-2 px-4 text-sm border-r-2 border-grey h-20"
														style={{
															background: `${
																backgroundColor ? backgroundColor : 'white'
															}`,
														}}
														aria-label={recommendation}
														aria-describedby={footNoteId}
													>
														<p
															className="h-auto overflow-y-hidden"
															style={{
																color: `${textColor ? textColor : 'black'}`,
															}}
														>
															{text}
														</p>
													</td>
												)}
											</>
										)}
									</>
								);
							})}
						</tr>
					))}
				</tbody>
			</table>
			{tableFootNotes && (
				<RichText>
					<div
						className="p-8"
						dangerouslySetInnerHTML={{
							__html: tableFootNotes,
						}}
					/>
				</RichText>
			)}
		</div>
	);
};

export default DesktopRiskTable;
