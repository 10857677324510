/**
 * BannerImage
 */

import React from 'react';

interface Props {
	/** Banner image heading */
	heading: string;

	/** Banner image src */
	imgSrc: string;
}

/** Banner image component. */
const BannerImage: React.FC<Props> = ({ imgSrc, heading }) => (
	<header className="mb-16 md:mb-0">
		<div
			style={{ backgroundImage: `url(${imgSrc})` }}
			className="w-full h-top-image-mobile md:h-top-image bg-no-repeat bg-cover"
		>
			<div className="mx-auto main-w-container relative h-full block">
				<div className="bg-white p-8 rounded-lg inline-block left-0 right-0 absolute -bottom-4 md:bottom-8 md:left-8 md:right-unset xl:left-0">
					<h1 id="sp-heading" className="font-bold mb-0">
						{heading}
					</h1>
				</div>
			</div>
		</div>
	</header>
);

export default BannerImage;
