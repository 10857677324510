import clsx from 'clsx';
import BaseCustomControl from 'components/Map/Controls/CustomControls/BaseCustomControl';
import MapContext from 'components/Map/MapContext';
import { linear } from 'ol/easing';
import React, { useContext } from 'react';

interface Props {
	zoomInLabel?: string;
	zoomOutLabel?: string;
}

const ZoomControl: React.FC<Props> = ({ zoomInLabel, zoomOutLabel }) => {
	const { map } = useContext(MapContext);

	function handleZoomin() {
		const view = map.getView();
		const currentZoom = view.getZoom();

		if (currentZoom)
			view.animate({
				zoom: currentZoom + 2,
				duration: 250,
				easing: linear,
			});
	}

	function handleZoomOut() {
		const view = map.getView();
		const currentZoom = view.getZoom();

		if (currentZoom)
			view.animate({
				zoom: currentZoom - 2,
				duration: 250,
				easing: linear,
			});
	}

	return (
		<div
			className={clsx(
				'ol-zoom ol-unselectable ol-control',
				'flex flex-col ol-zoom-container'
			)}
		>
			<BaseCustomControl
				icon="zoomOut"
				title={zoomOutLabel}
				labelPosition="left"
				handleControl={handleZoomOut}
				className="control-button ol-zoom-out group"
				active={false}
			/>

			<BaseCustomControl
				icon="zoomIn"
				title={zoomInLabel}
				labelPosition="left"
				handleControl={handleZoomin}
				className="control-button ol-zoom-in group"
				active={false}
			/>
		</div>
	);
};

export default ZoomControl;
