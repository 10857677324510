/**
 * Map
 */

import React, {
	RefObject,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import clsx from 'clsx';
import DirectionMeter from 'components/DirectionMeter';
import Icon from 'components/Icon';
import Legend from 'components/Legend';
import {
	CenterUserControl,
	CustomControls,
	DecliningScaleControl,
	ForecastAreasControl,
	LegendControl,
	ReportedAvalanchesControl,
	SlopeControl,
	ZoomControl,
} from 'components/Map/Controls';
import ResetDirectionMeterControl from 'components/Map/Controls/CustomControls/ResetDirectionMeterControl';
import { Layers, TileLayer } from 'components/Map/Layers';
import DecliningScaleLayer from 'components/Map/Layers/DecliningScaleLayer';
import ForecastLayer from 'components/Map/Layers/ForecastLayer';
import ForecastPolygonLayer from 'components/Map/Layers/ForecastPolygonLayer';
import ReportedAvalancheLayer from 'components/Map/Layers/ReportedAvalancheLayer';
import SlopeDirectionLayer from 'components/Map/Layers/SlopeDirectionLayer';
import MapContainer from 'components/Map/MapContainer';
import 'ol/ol.css';
import * as source from 'ol/source';
import { MapModel } from 'types/epi';
import { ReportedAvalanches } from 'types/mapTypes/MapModel';
import { DirectionMeter as DirectionMeterModel } from 'types/pageTypes/area_page';
import ExtraPolygonLayer from './Layers/ExtraPolygonLayer';

/** Main description for this component. */
const Map: React.FC<
	MapModel & {
		mapClassName?: string;
		/** this bool allow to know if we are in mobile device or not */
		isMobile?: boolean;
		onCLickBackToArea?: (tab: 'map' | 'area') => void;
		isAreaPage?: boolean;
	}
> = ({
	centerCoordinates,
	zoomLevel,
	maxZoomLevel,
	minZoomLevel,
	tileUrl,
	areas,
	sectionLabel,
	controls,
	legend,
	reportedAvalanches,
	closeText,
	layers,
	directions,
	mapClassName,
	myPositionMarkerIconUrl,
	isMobile,
	areaId,
	onCLickBackToArea,
	isAreaPage = false,
}) => {
	const {
		decliningScale,
		forecastArea,
		fullscreen,
		legend: legendMeta,
		myPosition,
		reportedAvalanches: reportedAvalanchesMeta,
		slopeDirections,
		zoomIn,
		zoomOut,
		resetDirections,
		backToArea,
	} = controls;

	const tempRef = useRef<HTMLButtonElement>(null);
	const [mapRef, setMapRef] = useState<RefObject<HTMLButtonElement>>(tempRef);
	const defaultFullscreen = isMobile && isAreaPage;

	const [setupCount, setSetupCount] = useState(0);
	const [isSetupComplete, setIsSetupComplete] = useState(false);

	const handleSetupComplete = useCallback(() => {
		setSetupCount((prevCount) => prevCount + 1);
	}, []);

	useEffect(() => {
		if (setupCount === reportedAvalanches.length) {
			setIsSetupComplete(true);
		}
	}, [setupCount, reportedAvalanches.length]);

	const [legendIsVisible, setLegendVisibility] = useState(false);
	const [avalanchePanel, setAvalanchePanel] = useState<{
		visibility: boolean;
		data?: ReportedAvalanches;
	}>({
		visibility: false,
		data: undefined,
	});

	// Used to toggle layers on and off using controls.
	const [layerState, setLayers] = useState({
		forecast: true,
		reportedAvalanches: false,
		slopeDirection: false,
		decliningScale: false,
	});

	/**
	 * Used to handle the direction meter on the left hand side of the map.
	 */
	type DirectionData = { state: boolean; code: string; label?: string };
	const [activeDirections, setActiveDirections] = useState<
		Record<keyof Omit<DirectionMeterModel, 'title'>, DirectionData>
	>({
		eastPanel: directions.eastPanel,
		northEastPanel: directions.northEastPanel,
		northPanel: directions.northPanel,
		northWestPanel: directions.northWestPanel,
		southEastPanel: directions.southEastPanel,
		southPanel: directions.southPanel,
		southWestPanel: directions.southWestPanel,
		westPanel: directions.westPanel,
	});

	function handleLayerToggle(layer: keyof typeof layerState) {
		if (layer === 'decliningScale' && layerState.slopeDirection) {
			resetDirectionMeter();
			setLayers((layerStates) => ({
				...layerStates,
				slopeDirection: false,
			}));
		}

		setLayers((prevLayerStates) => ({
			...prevLayerStates,
			[layer]: !prevLayerStates[layer],
		}));
	}

	function handleLegendToggle() {
		setLegendVisibility((prevState) => !prevState);
	}

	function openAvalanchePanel(data: ReportedAvalanches) {
		setAvalanchePanel({
			visibility: true,
			data: data,
		});
	}

	function updateActiveDirections(panel: keyof typeof activeDirections) {
		setLayers((layerStates) => ({
			...layerStates,
			decliningScale: false,
		}));
		setActiveDirections((prevActiveDirections) => ({
			...prevActiveDirections,
			[panel]: {
				...activeDirections[panel],
				state: !activeDirections[panel].state,
			},
		}));
	}

	function resetDirectionMeter() {
		setActiveDirections({
			eastPanel: directions.eastPanel,
			northEastPanel: directions.northEastPanel,
			southEastPanel: directions.southEastPanel,
			westPanel: directions.westPanel,
			northWestPanel: directions.northWestPanel,
			southWestPanel: directions.southWestPanel,
			northPanel: directions.northPanel,
			southPanel: directions.southPanel,
		});
	}

	useEffect(() => {
		function setDefaults() {
			setLayers({
				forecast: true,
				decliningScale: false,
				reportedAvalanches: false,
				slopeDirection: false,
			});
			setLegendVisibility(false);
			resetDirectionMeter();
		}

		setDefaults();

		return setDefaults;
	}, []);

	return (
		<section
			id="map-container"
			ref={tempRef}
			aria-label={sectionLabel}
			className={clsx('flex relative map md:h-full', mapClassName)}
		>
			<MapContainer
				{...{
					zoomLevel,
					maxZoomLevel,
					minZoomLevel,
					centerCoordinates,
					tileUrl,
					fullscreen,
					defaultFullscreen,
				}}
			>
				<Layers>
					<TileLayer
						source={
							new source.XYZ({
								imageSmoothing: true,
							})
						}
					/>
					{!isAreaPage && <ForecastLayer areaForecasts={areas} />}
					{reportedAvalanches.map((a, i) => (
						<ReportedAvalancheLayer
							key={i}
							avalanche={a}
							handleOnClick={openAvalanchePanel}
							active={layerState.reportedAvalanches}
							onSetupComplete={handleSetupComplete}
							isSetupComplete={isSetupComplete}
						/>
					))}
					{layers &&
						layers
							.filter((l) => l.name === 'Polygon')
							.map((l, index) => (
								<ForecastPolygonLayer
									key={index}
									layer={l}
									active={layerState.forecast}
									areaId={isAreaPage ? areaId : null}
								/>
							))}
					{layers &&
						areaId &&
						layers
							.filter((l) => l.name === 'ExtraPolygon')
							.map((l, index) => (
								<ExtraPolygonLayer
									key={index}
									layer={l}
									active={layerState.forecast}
									areaId={areaId}
								/>
							))}

					{layers &&
						layers
							.filter((l) => l.name === 'SlopeAspect')
							.map((l, index) => (
								<SlopeDirectionLayer
									key={index}
									activeDirections={activeDirections}
									layer={l}
									active={Object.values(activeDirections).some((d) => d.state)}
								/>
							))}

					{layers &&
						layers
							.filter((l) => l.name === 'SlopeInclination')
							.map((l, index) => (
								<DecliningScaleLayer
									key={index}
									layer={l}
									active={layerState.decliningScale}
								/>
							))}
				</Layers>
				{typeof window !== 'undefined' && (
					<CustomControls>
						<div className={clsx('control-btn-container')}>
							<ForecastAreasControl
								title={forecastArea.title}
								handleClick={() => handleLayerToggle('forecast')}
								active={layerState.forecast}
							/>
							<DecliningScaleControl
								title={decliningScale.title}
								handleClick={() => handleLayerToggle('decliningScale')}
								active={layerState.decliningScale}
							/>
							<ReportedAvalanchesControl
								title={reportedAvalanchesMeta.title}
								active={layerState.reportedAvalanches}
								handleClick={() => handleLayerToggle('reportedAvalanches')}
							/>
							<SlopeControl
								title={slopeDirections.title}
								handleClick={() => handleLayerToggle('slopeDirection')}
								active={layerState.slopeDirection}
							/>
							<CenterUserControl
								title={myPosition.title}
								myPositionMarkerIconUrl={myPositionMarkerIconUrl}
							/>

							<ZoomControl
								zoomInLabel={zoomIn.title}
								zoomOutLabel={zoomOut.title}
							/>
						</div>
						<LegendControl
							title={legendMeta.title}
							handleClick={handleLegendToggle}
							active={legendIsVisible}
						/>

						{isMobile && backToArea && (
							<div className="flex justify-center mt-5 mx-5">
								<button
									className="bg-grey-light w-full h-12 z-20 flex justify-center custom-border items-center rounded-md shadow-md mt-3 backToArea"
									onClick={() => onCLickBackToArea && onCLickBackToArea('area')}
								>
									<Icon
										icon="chevron"
										direction="right"
										size={1}
										className="mr-5"
									/>
									{backToArea.title}
								</button>
							</div>
						)}
					</CustomControls>
				)}

				<div className="absolute bottom-15 z-20 left-0">
					{/* reported-avalanche-slide-block */}
					<div
						id="reported-avalanche-slide-block"
						className={clsx(
							avalanchePanel.visibility ? '' : 'collapse',
							avalanchePanel.visibility ? 'h-42' : 'h-0',
							!avalanchePanel.visibility && 'shadow-pop-up-map'
						)}
					>
						<div className="flex mt-6 ml-4">
							{avalanchePanel.visibility && (
								<img
									src={avalanchePanel.data?.icon?.src}
									alt={avalanchePanel.data?.icon?.alt}
									className="w-9 h-14 mr-4"
								/>
							)}
							<div className="text-left w-48">
								<p className="font-standard text-base mb-1 text-grey-darker leading-5">
									{avalanchePanel.data?.date}
								</p>
								<p className="text-xl font-bold leading-7 mb-2">
									{avalanchePanel.data?.size}
								</p>
								<p className="text-base leading-5">
									{avalanchePanel.data?.trigger}
								</p>
								<p className="text-base leading-5">
									{avalanchePanel.data?.character}
								</p>
							</div>
						</div>
						<button
							aria-label={closeText}
							onClick={() =>
								setAvalanchePanel((panel) => ({
									...panel,
									visibility: false,
								}))
							}
							className={clsx(
								'w-10 h-10 bg-white mt-6 mr-6',
								'shadow-md rounded-md',
								'flex justify-center items-center'
							)}
						>
							{avalanchePanel.visibility && (
								<Icon icon="remove" size={1} aria-hidden="true" />
							)}
						</button>
					</div>
					{/* This Component only works for the SlopeDirection */}
					{layerState.slopeDirection && (
						<div className="bg-white w-56 h-40 flex items-end   shadow-pop-up-map rounded-tr-lg rounded-br-lg transition-all transform translate-x-0 delay-200 ease-in-out duration-500">
							<DirectionMeter
								title={directions.title}
								{...activeDirections}
								onPanelClicked={(panel) => updateActiveDirections(panel)}
								isInteractive
								className="ml-6"
							/>
							{Object.values(activeDirections).some((e) => e.state) &&
								layerState.slopeDirection && (
									<ResetDirectionMeterControl
										title={resetDirections.title}
										handleClick={() => resetDirectionMeter()}
									/>
								)}
						</div>
					)}
				</div>

				<Legend
					{...legend}
					isVisible={legendIsVisible}
					hide={handleLegendToggle}
				/>
			</MapContainer>
		</section>
	);
};

export default Map;
