/**
 * RouteHandler
 */

import Loader from 'components/Loader';
import Text from 'components/Text';
import BaseLayout from 'layouts/BaseLayout';
import { PagesMapper } from 'pages';
import ErrorPage404 from 'pages/ErrorPage404';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { fetchPage, selectContent, selectModel } from 'store/modules/model';
import { selectReact, updateRenderingState } from 'store/modules/react';
import { RenderingStates } from 'types/epi';
import { ErrorPage404Model } from 'types/pages';

export interface ParamTypes {
	siteRoute: string;
}
/** Loads data and renders the correct page based on the route. */
const RouteHandler: React.FC = () => {
	let { siteRoute } = useParams<ParamTypes>();
	siteRoute = siteRoute || '/';
	const ariaLiveRef = useRef<HTMLDivElement>(null);
	const emptyFocusDivRef = useRef<HTMLDivElement>(null);
	const pageRef = useRef<any>(null);
	const { action } = useHistory();
	const dispatch = useDispatch();
	const { error, loading } = useSelector(selectModel);
	const pageContent = useSelector(selectContent);
	const { renderingState, apiUrl } = useSelector(selectReact);
	const [loaderVisible, setLoaderVisible] = useState(false);
	const Page = PagesMapper(pageContent);
	const errorPageContent = useSelector(selectContent) as ErrorPage404Model;

	// Fix siteRoute so we don't request '//'.
	if (
		apiUrl &&
		siteRoute &&
		apiUrl.charAt(apiUrl.length - 1) === '/' &&
		siteRoute.charAt(0) === '/'
	) {
		siteRoute = siteRoute.substr(1);
	}

	// Load the page
	useEffect(() => {
		// First hydrate rendering we wont be doing any request, becase we already have the data from the SSR.
		if (renderingState !== RenderingStates.ClientSide && pageContent) {
			return;
		}

		dispatch(fetchPage(apiUrl, siteRoute));
		// eslint-disable-next-line
	}, [apiUrl, siteRoute, dispatch]);

	// Set renderingState to clientside after the first (hydration) render.
	useEffect(() => {
		dispatch(updateRenderingState(RenderingStates.ClientSide));
		// eslint-disable-next-line
	}, []);

	// If we have been waiting for the response more than 400ms we display the loader
	useEffect(() => {
		let loaderTimeout: any;

		if (loading) {
			loaderTimeout = setTimeout(() => {
				// Tell sighted users
				setLoaderVisible(true);
				if (ariaLiveRef.current) {
					ariaLiveRef.current.innerHTML = 'Sidan laddar';
				}
				setTimeout(() => {
					if (ariaLiveRef.current) {
						ariaLiveRef.current.innerHTML = '';
					}
				}, 500);
			}, 400);
		} else {
			setLoaderVisible(false);
			if (action === 'PUSH') {
				window.scrollTo(0, 0);
			}
		}

		return () => {
			if (loaderTimeout) {
				clearTimeout(loaderTimeout);
			}
		};
		// eslint-disable-next-line
	}, [loading]);

	// Page is loaded
	useEffect(() => {
		if (renderingState !== RenderingStates.ClientSide && pageContent) {
			return;
		}

		if (pageContent) {
			if (ariaLiveRef.current) {
				ariaLiveRef.current.innerHTML = 'Sidan har laddats';
			}
			setTimeout(() => {
				if (ariaLiveRef.current) {
					ariaLiveRef.current.innerHTML = '';
				}
			}, 100);

			if (emptyFocusDivRef.current) {
				emptyFocusDivRef.current.focus();
				emptyFocusDivRef.current.scrollTo(0, 0);
			}
			setTimeout(() => {
				if (pageRef.current) {
					const firstH1 = pageRef.current.querySelector('h1');
					if (firstH1) {
						firstH1.setAttribute('tabindex', -1);
						firstH1.style.outline = 'none';
						firstH1.focus();
					} else {
						pageRef.current.focus();
					}
				}
			}, 0);
			pageRef.current?.scrollTo(0, 0);
		}
	}, [pageContent]);

	return (
		<>
			{loading && loaderVisible && (
				<div className="z-1000 h-screen w-screen bg-white fixed inset-0 flex justify-center items-center">
					<Loader variant="default" />
				</div>
			)}

			{error && error.indexOf('404') !== -1 && (
				<ErrorPage404
					error={error}
					heading={errorPageContent.heading}
					text={errorPageContent.text}
					buttonText={errorPageContent.buttonText}
					linkTo={errorPageContent.linkTo}
				/>
			)}
			{pageContent && !error && (
				<div ref={pageRef} tabIndex={-1} style={{ outline: 'none' }}>
					<BaseLayout modelType={pageContent.modelType}>
						<Page {...pageContent} />
					</BaseLayout>
				</div>
			)}
			<Text as="screenReader" aria-live="assertive" ref={ariaLiveRef}></Text>
			<div
				tabIndex={-1}
				ref={emptyFocusDivRef}
				style={{ outline: 'none' }}
			></div>
		</>
	);
};
export { RouteHandler };
