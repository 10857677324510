/**
 * NewsPage
 */
import React from 'react';
import { NewsPageModel } from 'types/pages';

import Breadcrumb from 'components/Breadcrumb';
import EpiFragments from 'components/EpiFragments';
import BannerImage from 'components/BannerImage';
import { Grid, Cell } from 'components/Grid';

/** Newspage component. */
const NewsPage: React.FC<NewsPageModel> = ({
	heading,
	preamble,
	text,
	bannerImage,
	publishedDate,
	publishedLabel,
	contactForMoreInformationLabel,
	contactPersons,
}) => {
	return (
		<article aria-labelledby="sp-heading">
			{bannerImage && (
				<BannerImage heading={heading} imgSrc={bannerImage.src} />
			)}

			<div className="mx-auto main-w-container px-8 xl:px-0">
				<Breadcrumb className="mt-1 mb-2" />

				<Grid className="flex justify-between">
					<Cell span={12} desktop={7}>
						{publishedDate && (
							<p className="text-base mb-4">
								{publishedLabel}: {publishedDate}
							</p>
						)}

						{preamble && <p className="text-base font-bold mb-6">{preamble}</p>}

						{text && <EpiFragments fragments={text.fragments} />}
					</Cell>

					{contactPersons && contactPersons.length > 0 && (
						<Cell span={12} desktop={5} className="md:pl-12">
							<div className="rounded-xl border-2 border-grey-light p-6">
								<h2
									id="contact-person-heading"
									className="text-xl font-bold font-standard"
								>
									{contactForMoreInformationLabel}
								</h2>
								<div className="border-b-3 border-orange w-12 mt-2 mb-4" />

								{contactPersons.map((person, i) => (
									<div
										key={i}
										className="text-base mt-6 pb-4 border-b-2 border-grey-light last:border-b-0 last:pb-0"
									>
										<h3
											id={`contact-person-name-${i}`}
											className="text-h5 font-bold"
										>
											{person.name}
										</h3>

										{person.role && (
											<p className="text-h6 text-grey-darker mb-2">
												{person.role}
											</p>
										)}

										{person.telephoneNumbers &&
											person.telephoneNumbers.length > 0 && (
												<p>
													<span className="font-bold mr-2">
														{person.telephoneLabel}:
													</span>
													{person.telephoneNumbers.map(
														(number, index, array) => (
															<React.Fragment key={index}>
																<a
																	aria-label="telephone number link"
																	href={`tel:${number}`}
																	className="underline hover:no-underline"
																>
																	{number}
																</a>
																{index !== array.length - 1 ? (
																	<span>, </span>
																) : (
																	''
																)}
															</React.Fragment>
														)
													)}
												</p>
											)}

										{person.emailAddress && (
											<p>
												<span className="font-bold mr-2">
													{person.emailLabel}:
												</span>
												<a
													aria-label="email link"
													href={`mailto:${person.emailAddress}`}
													className="text-link underline hover:no-underline break-words"
												>
													{person.emailAddress}
												</a>
											</p>
										)}
									</div>
								))}
							</div>
						</Cell>
					)}
				</Grid>
			</div>
		</article>
	);
};

export default NewsPage;
