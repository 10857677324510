/**
 * Image
 */

import clsx from 'clsx';
import React from 'react';

export interface ImageProps {
	/** The image src */
	src?: string;

	/** The image alt */
	alt?: string;

	/** Pass optional classnames for the html */
	className?: string;

	/** The image figure caption */
	figCaption?: string;

	/** Width of the Image (Optional)*/
	width?: number;

	/** Height of the Image (Optional)*/
	height?: number;

	/** Optional other html props will be spread on the component */
	[htmlAttributes: string]: any;
}

/** The image component with or without the figure caption. */
const Image: React.FC<ImageProps> = ({
	src,
	alt,
	className,
	figCaption,
	height,
	width,
	...htmlAttributes
}) => {
	if (!src) {
		return null;
	}

	const hasDimensions = height && width;

	return (
		<>
			{!figCaption && (
				<img
					width={width}
					height={height}
					src={src}
					alt={alt}
					className={clsx(
						// !hasDimensions && 'w-full h-auto',
						className,
						'block',
						'max-w-xs md:max-w-md lg:max-w-3xl xl:max-w-5xl'
					)}
					{...htmlAttributes}
				/>
			)}
			{figCaption && (
				<figure className="mb-4">
					<img
						src={src}
						alt={alt}
						className={clsx(
							!hasDimensions && 'w-full',
							className,
							'block',
							'mb-0'
						)}
						style={{ height, width }}
						{...htmlAttributes}
					/>
					<figcaption className="py-2 px-3 bg-gray-200 text-black w-full">
						{figCaption}
					</figcaption>
				</figure>
			)}
		</>
	);
};

export default Image;
