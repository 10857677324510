/**
 * MobileStartPage
 */
import React, { useState } from 'react';
import Icon from 'components/Icon';
import TabButton from 'components/TabButton';
import { StartPageModel } from 'types/pages';
import Map from 'components/Map';
import AreaList from 'components/AreaList';
import { AreaPageLink } from 'types/global';
import { MapModel } from 'types/epi';
import icons from 'theme/icons';

type IconList = keyof typeof icons;

export interface MobileStartPageProps {
	areaPageLinksArea: {
		heading: string;
		validUntilText: string;
		validToDate?: string;
		areaPageLinks: Array<AreaPageLink>;
	};
	map: MapModel;
	mobileMapLabel?: string;
	mobileAreasLabel?: string;
}

type TabsConfig = {
	label?: string;
	icon: IconList;
	iconClass?: string;
};

type KeyMap = {
	ArrowRight: any;
	ArrowLeft: any;
	Home: any;
	End: any;
};

/** Main description for this component. */
const MobileStartPage: React.FC<MobileStartPageProps> = ({
	areaPageLinksArea,
	map,
	mobileMapLabel,
	mobileAreasLabel,
}) => {
	const tabsConfig: TabsConfig[] = [
		{
			label: mobileMapLabel,
			icon: 'map' as IconList,
			iconClass: 'fill-none',
		},
		{
			label: mobileAreasLabel,
			icon: 'list' as IconList,
		},
	];

	const [selectedIndex, setSelectedIndex] = useState<number>(0);
	const tabRefs = React.useRef<any>({});
	const handleTabChange = (index: number) => setSelectedIndex(index);

	const setTabIndex = (index: number) => {
		const tab = tabRefs.current[index];
		if (tab) {
			tab.focus();
		}
	};
	const onKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
		const count = tabsConfig.length;
		const nextTab = () => setTabIndex((selectedIndex + 1) % count);
		const prevTab = () => setTabIndex((selectedIndex - 1 + count) % count);
		const firstTab = () => setTabIndex(0);
		const lastTab = () => setTabIndex(count - 1);

		const keyMap: KeyMap = {
			ArrowRight: nextTab,
			ArrowLeft: prevTab,
			Home: firstTab,
			End: lastTab,
		};

		const action = keyMap[event.key as keyof KeyMap];

		if (action) {
			event.preventDefault();
			action();
		}
	};

	return (
		<>
			<div role="tablist" className="w-full flex flex-nowrap">
				{tabsConfig.map((tab, index) => (
					<TabButton
						key={`tab-${index}`}
						onClick={() => handleTabChange(index)}
						ref={(element) => (tabRefs.current[index] = element)}
						aria-controls={`tabpanel-id-${index}`}
						aria-selected={selectedIndex === index}
						id={`tab-id-${index}`}
						onKeyDown={onKeyDown}
						onFocus={() => setSelectedIndex(index)}
						tabIndex={selectedIndex === index ? 0 : -1}
						label={tab.label}
						active={selectedIndex === index}
					>
						<Icon
							icon={tab.icon}
							size={1}
							className={`mr-2 ${tab.iconClass}`}
						/>
					</TabButton>
				))}
			</div>
			<div
				id="tabpanel-id-0"
				role="tabpanel"
				aria-labelledby="tab-id-0"
				hidden={selectedIndex !== 0}
			>
				<Map {...map} />
			</div>
			<div
				id="tabpanel-id-1"
				role="tabpanel"
				aria-labelledby="tab-id-1"
				hidden={selectedIndex !== 1}
			>
				<div className="flex justify-center">
					<AreaList {...areaPageLinksArea} />
				</div>
			</div>
		</>
	);
};

export default MobileStartPage;
