/**
 * Card
 */

import React from 'react';
import { Image } from 'types/global';

import Icon from 'components/Icon';
import Link from 'components/Link';

interface Props {
	id: number | string;
	icon?: 'circleExclamation' | 'suitcase' | 'snowboarding';
	image?: Image;
	body: string;
	heading: string;
	url: string;
}

/** Navigation Page Card. */
const Card: React.FC<Props> = ({ image, icon, heading, body, url, id }) => {
	return (
		<article
			className="w-img-card remove-mr"
			aria-labelledby={`icon-puff-article-${id}`}
		>
			<Link
				href={url}
				className="box-border flex flex-col items-center transition ease-in-out duration-300 group bg-white rounded-lg border border-gray-200 shadow-md hover:shadow-lg mb-8 md:mb-16 group h-img-card"
			>
				{icon && (
					<div className="bg-grey-light h-card-img w-full">
						<div className="h-card-img flex items-center justify-center">
							<Icon icon={icon} size={7} />
						</div>
					</div>
				)}

				{image && (
					<div className="w-full bg-grey-light">
						<img
							className="rounded-t-lg bg-grey-light w-full block h-card-img object-cover"
							src={image.src}
							alt={image.alt}
						/>
					</div>
				)}

				<div className="px-4 pt-4 pb-0 min-h-11 h-11 w-full">
					<h2
						id={`icon-puff-article-${id}`}
						className="text-xl leading-7 font-standard font-bold mb-4 group-hover:underline flex items-baseline justify-between"
					>
						{heading}
						<Icon size={1} icon="chevron" direction="left" className="mr-2" />
					</h2>
					<p className="leading-5 mb-8 h-[60px] overflow-y-hidden">{body}</p>
				</div>
			</Link>
		</article>
	);
};

export default Card;
