import Button from 'components/Button';
import Icon from 'components/Icon';
import Link from 'components/Link';
import React from 'react';
import { Link as LinkCustom } from 'types/global';
import { Forecast as ForecastType } from 'types/pageTypes/area_page';

interface Props {
	heading: string;
	areaName: string;
	forecast: ForecastType;
	historical: {
		isHistorical: boolean;
		content: string;
		link: Omit<LinkCustom, 'target' | 'active' | 'newTab'>;
	};
	previewControls?: {
		previewButton: {
			url: string;
			text: string;
		};
		nextButton: {
			url: string;
			text: string;
		};
		previousButton: {
			url: string;
			text: string;
		};
	};
	avalancheHazardText: string;
	avalancheScaleLink: LinkCustom;
	isMobile: boolean;
}

const AreaHeader: React.FC<Props> = ({
	heading,
	areaName,
	forecast,
	historical,
	previewControls,
	avalancheHazardText,
	avalancheScaleLink,
	isMobile,
}) => {
	const {
		validFrom,
		validFromPrefix,
		validToPrefix,
		validTo,
		riskImage,
		riskLabel,
	} = forecast || {};

	return (
		<>
			<div className="flex justify-between flex-wrap lg:flex-nowrap">
				<div className="mt-4 lg:mt-0">
					<span className="text-h4 font-semibold leading-5 text-orange font-standard">
						{heading}
					</span>
					<h1
						id="area-name"
						className="text-2.5xl font-standard leading-8 font-bold mt-2 sm:mt-3"
					>
						<span className="sr-only"> {heading}</span>
						{areaName}
					</h1>
					<div className="flex flex-wrap mt-3 mb-4 lg:mb-0">
						<p className="text-sm md:text-h5 lg:text-h4 font-standard text-black mb-1 lg:mb-0">
							<span>{validFromPrefix} </span>
							<span>{validFrom}</span>
						</p>
						{!isMobile && (
							<span className="w-4 border-2 h-1 self-center -mt-1 lg:mt-0 mx-3 border-grey-darker bg-grey-darker"></span>
						)}
						<p className="text-sm md:text-h5 lg:text-h4 font-standard text-black">
							<span>{validToPrefix} </span>
							<span>{validTo}</span>
						</p>
					</div>

					{historical?.isHistorical && (
						<p className="inline-flex flex-wrap lg:flex-nowrap items-center rounded-md mb-6 mt-4 p-2 text-orange border-orange border-1">
							<Icon size={2} icon="iconWarning" className="mr-2 text-orange" />
							<span>{historical.content}</span>

							<Link
								href={historical?.link.url}
								fullReload
								className="underline text-black mr-4 ml-8 lg:ml-4"
							>
								{historical.link.text}
								<Icon
									icon="chevron"
									size={1}
									direction="left"
									className="text-black ml-2"
								/>
							</Link>
						</p>
					)}

					{previewControls && (
						<div className="my-6 flex flex-wrap lg:flex-nowrap">
							{previewControls.previewButton && (
								<Button
									type="link"
									href={previewControls.previewButton.url}
									variant="secondary"
									className="group mr-4 mb-4 md:mb-0"
								>
									{previewControls.previewButton.text}
								</Button>
							)}

							<div>
								{previewControls.previousButton && (
									<Button
										type="link"
										href={previewControls.previousButton.url}
										variant="secondary"
										className="group h-12 mr-4"
									>
										<span className="sr-only">
											{previewControls.previousButton.text}
										</span>
										<Icon
											icon="chevron"
											size={1}
											direction="right"
											aria-hidden
										/>
									</Button>
								)}

								{previewControls.nextButton && (
									<Button
										type="link"
										href={previewControls.nextButton.url}
										variant="secondary"
										className="group h-12"
									>
										<span className="sr-only">
											{previewControls.nextButton.text}
										</span>
										<Icon
											icon="chevron"
											size={1}
											direction="left"
											aria-hidden
										/>
									</Button>
								)}
							</div>
						</div>
					)}
				</div>

				<div className="w-full sm:w-auto my-4 sm:mt-8 flex justify-between lg:justify-end -mb-12 sm:-mb-8 lg:mb-0">
					<div>
						<span className="text-sm lg:text-h4 font-semibold text-grey-darker">
							{avalancheHazardText}
						</span>
						<p className="text-h5 lg:text-2xl font-standard font-bold text-orange">
							{riskLabel}
						</p>
						{avalancheScaleLink && (
							<Link
								href={avalancheScaleLink.url}
								className="text-sm hover:underline w-[165px] block mt-2"
							>
								<Icon
									icon="info"
									size={1}
									className="text-black mr-2 rounded-3xl border-2 border-black p-[2px]"
								/>

								{avalancheScaleLink.text}
								<Icon
									icon="chevron"
									size={99}
									direction="left"
									className="text-black ml-1"
								/>
							</Link>
						)}
					</div>
					<div className="mb-0 flex -mr-9">
						{riskImage && (
							<img
								src={riskImage.src}
								alt={riskImage.alt}
								className="w-full h-full max-h-risk-icon max-w-risk-icon -mt-8"
							/>
						)}
					</div>
				</div>
			</div>
			<div className="mobile-w sm:w-full -ml-8 sm:ml-0 mt-4 border-1 border-grey"></div>
		</>
	);
};

export default AreaHeader;
