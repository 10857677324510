import clsx from 'clsx';
import Icon from 'components/Icon';
import MapContext from 'components/Map/MapContext';
import { MapBrowserEvent, Overlay } from 'ol';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import { transform } from 'ol/proj';
import { Vector } from 'ol/source';
import { Circle, Style } from 'ol/style';
import React, {
	RefObject,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { FaMountain } from 'react-icons/fa';
import { ReportedAvalanches } from 'types/mapTypes/MapModel';

interface Props {
	avalanche: ReportedAvalanches;
	handleOnClick(data: ReportedAvalanches): any;
	active: boolean;
	onSetupComplete: () => void;
	isSetupComplete: boolean;
}

const ReportedAvalancheLayer: React.FC<Props> = ({
	avalanche,
	handleOnClick,
	active,
	onSetupComplete,
	isSetupComplete,
}) => {
	const { map } = useContext(MapContext);

	const [markerOverlay, setMarkerOverlay] = useState<Overlay>();

	const setupReportedAvalancheMarkers = useCallback(() => {
		function addHoverEffect(e: MapBrowserEvent<UIEvent>) {
			if (e.dragging) return;

			const pixel = map.getEventPixel(e.originalEvent);
			const hit = map.hasFeatureAtPixel(pixel);

			map.getTargetElement().style.cursor = hit ? 'pointer' : '';
		}

		if (!map) return;

		const layerSource = new Vector({});

		const markerEl = document.getElementById(
			'reportedAvalanche' + avalanche.coordinates
		);
		const convertedCoordinates = transform(
			avalanche.coordinates,
			'EPSG:4326',
			'EPSG:3857'
		);

		if (markerEl == null) return;

		var markerOverlay = new Overlay({
			id: 'marker' + avalanche.coordinates,
			position: convertedCoordinates,
			offset: [5, -17],
			element: markerEl,
			stopEvent: false,
		});

		setMarkerOverlay(markerOverlay);

		const circleFeature = new Feature({
			geometry: new Point(avalanche.coordinates),
		});

		circleFeature.setStyle(
			new Style({
				image: new Circle({
					radius: 15,
				}),
			})
		);

		layerSource.addFeatures([circleFeature]);

		const vectorLayer = new VectorLayer({
			source: layerSource,
			zIndex: 22,
			visible: active,
		});

		// Adds Layer with Area Markers
		map.addLayer(vectorLayer);

		//To add hover effect on Area Markers
		map.on('pointermove', addHoverEffect);

		// Notify that the setup is complete
		onSetupComplete();

		return () => {
			if (map) {
				map.removeLayer(vectorLayer);
				map.un('pointermove', addHoverEffect);
			}
		};
	}, [map, avalanche, active]);

	const toggleMarkerOverlay = useCallback(() => {
		if (markerOverlay) {
			active ? map.addOverlay(markerOverlay) : map.removeOverlay(markerOverlay);
		}
	}, [active, markerOverlay, map]);

	useEffect(setupReportedAvalancheMarkers, [setupReportedAvalancheMarkers]);

	useEffect(toggleMarkerOverlay, [toggleMarkerOverlay]);

	return (
		<>
			<button
				key={avalanche.coordinates.join('')}
				aria-label={avalanche.info}
				id={`reportedAvalanche${avalanche.coordinates}`}
				onClick={() => handleOnClick(avalanche)}
				className={clsx(
					'focus:outline-none',
					'cursor-pointer',
					'hover:bg-opacity-100 hover:shadow-sm'
				)}
			>
				{isSetupComplete && (
					<img
						src={avalanche.icon?.src}
						alt={avalanche.icon?.alt}
						className="w-8"
					/>
				)}
			</button>
		</>
	);
};

export default ReportedAvalancheLayer;
