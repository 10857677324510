/**
 * EpiFragments
 */

import React from 'react';
import EpiForms from 'components/EpiForms';
import { EpiFormsProps } from 'components/EpiForms/EpiForms';
import Image from 'components/Image';
import { PuffWithImage } from 'components/Puff';
import RichText from 'components/RichText';
import Text from 'components/Text';
import LinkWithImageBlock from 'components/LinkWithImageBlock';
import RiskTable from 'components/RiskTable';
import { PuffWithImageModel } from 'types/blocks';
import {
	FragmentModelTypes,
	HeadingFragmentModel,
	ImageFragmentModel,
	ListFragmentModel,
	RawFragmentModel,
	TableFragmentModel,
	UrlFragmentModel,
	LinkWithImageModel,
} from 'types/fragments';

interface Props {
	/** Epi properties to use for on page editing */
	[htmlAttributes: string]: any;

	/** Fragments that should be rendered. */
	fragments?: FragmentModelTypes[];
}

const getHeadingLevel = (level: number) => {
	switch (level) {
		case 1:
			return 'h1';
		case 2:
			return 'h2';
		case 3:
			return 'h3';
		case 4:
			return 'h4';
		case 5:
			return 'h5';
		case 6:
			return 'h6';
		default:
			return 'h2';
	}
};

const getFragment = (fragment: FragmentModelTypes, index: number) => {
	switch (fragment.modelType) {
		case 'LinkWithImageBlock':
			const linkWithImage = fragment as LinkWithImageModel;
			return (
				<LinkWithImageBlock
					heading={linkWithImage.heading}
					image={linkWithImage.image}
					url={linkWithImage.url}
					size="large"
					key={'link-' + index}
					index={index}
				/>
			);
		case 'HeadingFragment':
			const data = fragment as HeadingFragmentModel;
			return (
				<Text as={getHeadingLevel(data.level)} id={data.id} key={index}>
					<span dangerouslySetInnerHTML={{ __html: data.raw }} />
				</Text>
			);

		case 'PuffWithLargeImage':
			const puff = fragment as PuffWithImageModel;
			return (
				<div className="mb-4" key={index}>
					<PuffWithImage {...puff} />
				</div>
			);
		case 'Image':
			const imgData = fragment as ImageFragmentModel;

			return (
				<Image
					src={imgData.src}
					alt={imgData.alt}
					prop={imgData.prop}
					figCaption={imgData.caption}
					key={index}
					height={imgData.height}
					width={imgData.width}
					className="my-4"
				/>
			);
		case 'TableFragment':
			const tableData = fragment as TableFragmentModel;
			return (
				<div className="my-10" key={index}>
					<RiskTable {...tableData} />
				</div>
			);
		case 'FormContainer':
			const formContainer = fragment as EpiFormsProps;

			return (
				<div className="my-10" key={index}>
					<EpiForms {...formContainer} />
				</div>
			);
		case 'ListFragment':
			const listData = fragment as ListFragmentModel;
			return (
				<RichText>
					<div dangerouslySetInnerHTML={{ __html: listData.raw }} />
				</RichText>
			);
		case 'RawFragment':
			const raw = fragment as RawFragmentModel;
			return (
				<RichText key={index}>
					<div
						dangerouslySetInnerHTML={{
							__html: raw.raw,
						}}
					/>
				</RichText>
			);

		case 'UrlFragment':
			const { href, target, text, title, rel } = fragment as UrlFragmentModel;

			return (
				<RichText key={index}>
					<a
						href={href}
						target={target}
						title={title}
						rel={rel}
						dangerouslySetInnerHTML={{ __html: text }}
					/>
				</RichText>
			);
		default:
			return null;
	}
};

/** Component for rendering Episerver fragments. */
const EpiFragments: React.FC<Props> = ({ fragments, ...htmlAttributes }) => {
	return <div {...htmlAttributes}>{fragments?.map(getFragment)}</div>;
};

export default EpiFragments;
